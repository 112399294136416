.partners {
  align-items: center;
  display: flex;
  justify-content: center;
}

.partner-logo {
  max-width: 100%;
  opacity: 0;
  position: absolute;
  transition: opacity 125ms ease-in;

  &--active {
    opacity: 1;
  }

  &--alamos-gold { width: 224px; }
  &--arcelor-mittal { width: 249px; }
  &--asbestos { width: 225px; }
  &--asdr { width: 300px; }
  &--bagama { width: 252px; }
  &--barr { width: 128px; }
  &--barrick { width: 211px; }
  &--bgm { width: 95px; }
  &--black-iron { width: 69px; }
  &--bumigeb { width: 260px; }
  &--cameco { width: 184px; }
  &--canadian-royalties { width: 311px; }
  &--century { width: 140px; }
  &--cima { width: 219px; }
  &--cliffs { width: 287px; }
  &--detour-gold { width: 301px; }
  &--eldorado-gold { width: 300px; }
  &--goldcorp { width: 255px; }
  &--greenstone { width: 339px; }
  &--iamgold { width: 279px; }
  &--imperial { width: 189px; }
  &--jeffrey { width: 84px; }
  &--kinross { width: 235px; }
  &--mason-graphite { width: 325px; }
  &--metonor { width: 212px; }
  &--mfq { width: 300px; }
  &--mine-canadian-calartic { width: 251px; }
  &--mine-raglan { width: 278px; }
  &--nal-catl { width: 244px; }
  &--nantou { width: 148px; }
  &--niobec { width: 240px; }
  &--nordgold { width: 243px; }
  &--noront { width: 242px; }
  &--nouveau-monde { width: 298px; }
  &--nyrstar { width: 217px; }
  &--orezone { width: 336px; }
  &--osisko { width: 218px; }
  &--pierres-steatites { width: 296px; }
  &--potash-corp { width: 284px; }
  &--rio-tinto { width: 224px; }
  &--rio-tinto-ioc { width: 300px; }
  &--robex { width: 238px; }
  &--semafo { width: 175px; }
  &--sirios { width: 235px; }
  &--snc-lavalin { width: 252px; }
  &--srg-graphite { width: 80px; }
  &--tacora { width: 300px; }
  &--wesdome { width: 229px; }
  &--wsp { width: 224px; }
}

