.overlapped-box {
  align-items: center;
  background: $light-grey;
  display: flex;
  font-size: rem-calc(22);
  line-height: 1.3;
  position: relative;
  z-index: $z-overlapped-box;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__content {
    margin: rem-calc(50) 0;
  }

  &__image {
    margin-bottom: rem-calc(-50);
  }

  + .hero .hero__content::before {
    content: "";
    display: block;
    height: rem-calc(50);
  }
}

// IE 10 + IE 11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .overlapped-box__inner {
    display: block;
  }
}

@media screen and (max-width: 968px) {
  .overlapped-box__image,
  .overlapped-box + .hero .hero__content::before {
    display: none;
  }
}

