$button-background-color: #fff;

.button {
  background: $button-background-color;
  border: rem-calc(2) solid #1a264b;
  border-radius: rem-calc(21);
  color: $default-color;
  display: inline-block;
  font-size: rem-calc(17);
  font-weight: $weight-medium;
  letter-spacing: 0.25px;
  padding: 7px 25px;
  text-decoration: none;

  &--flat {
    border-color: $button-background-color;
  }

  &--more::after {
    content: "›";
    margin-left: 0.4em;
  }
}
