@font-face {
  font-family: 'NittiGrotesk';
  src: url('../fonts/NittiGrotesk-Light.woff2') format('woff2'), url('../fonts/NittiGrotesk-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NittiGrotesk';
  src: url('../fonts/NittiGrotesk.woff2') format('woff2'), url('../fonts/NittiGrotesk.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NittiGrotesk';
  src: url('../fonts/NittiGrotesk-Medium.woff2') format('woff2'), url('../fonts/NittiGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NittiGrotesk';
  src: url('../fonts/NittiGrotesk-Bold.woff2') format('woff2'), url('../fonts/NittiGrotesk-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  color: $default-color;
  font-family: $default-font-family;
  font-size: #{$default-font-size}px;
  font-weight: $weight-light;
  line-height: $default-line-height;
}

h1, .h1 {
  font-size: rem-calc(50);
}

h2, .h2 {
  font-size: rem-calc(28);
}

h3, .h3 {
  font-size: rem-calc(24);
}

h4, .h4 {
  font-size: rem-calc(22);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: $weight-medium;
  line-height: 1.2;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  &--light {
    font-weight: $weight-light;
  }
}

strong {
  font-weight: $weight-light;
}

a {
  color: $default-color;
  font-weight: $weight-medium;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
}

@media screen and (min-width: 1500px) {
  html { font-size: 23px; }
}

@media screen and (min-width: 1280px) {
  html { font-size: 18px; }
}

@media screen and (max-width: 1000px) {
  html { font-size: 15px; }
}

@media screen and (max-width: 768px) {
  html { font-size: 14px; }
}

@media screen and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 32px;
  }
}
