$default-font-family: 'NittiGrotesk', Helvetica Neue, Helvetica, Arial, sans-serif;
$default-color: #003562;
$default-font-size: rem-calc(18);
$default-line-height: 1.5;

$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-bold: 600;

$outer-padding: 70px;
$outer-padding-mobile: 18px;
$header-spacing: 70;
$dark-blue: darken($default-color, 10);
$light-blue: #c9e9f1;
$light-grey: #f9f9f9;

$z-content: 1;
$z-overlapped-box: 100;
$z-implication-title: 200;
$z-header-filter: 1000;
$z-header-inner: 1500;
$z-header: 2000;
