@use "sass:math";

$implication-transition-duration: 85ms;
$implication-transition-timing: ease-in;

.implication {
  background-color: #003562;
  color: #fff;
  font-size: rem-calc(24);
  min-height: 100vh;
  padding: 20vh 0 0;
  position: relative;
  transition: background-color 225ms ease-in;

  &::before {
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    content: "";
    height: 100%;
    opacity: 0.2;
    transition: background-image 225ms ease-in;
    width: 100%;

    position: absolute;
    left: 0;
    top: 0;
  }

  &--tone-0 { background-color: #003562; }
  &--tone-1 { background-color: #003058; }
  &--tone-2 { background-color: #012b4e; }
  &--tone-3 { background-color: #012544; }
  &--tone-4 { background-color: #012544; }
  &--tone-5 { background-color: #012b4e; }
  &--tone-6 { background-color: #003058; }
  &--tone-7 { background-color: #003562; }

  &--tone-0::before { background-image: url('../images/implications/implication-0.jpg'); }
  &--tone-1::before { background-image: url('../images/implications/implication-1.jpg'); }
  &--tone-2::before { background-image: url('../images/implications/implication-2.jpg'); }
  &--tone-3::before { background-image: url('../images/implications/implication-3.jpg'); }
  &--tone-4::before { background-image: url('../images/implications/implication-4.jpg'); }
  &--tone-5::before { background-image: url('../images/implications/implication-5.jpg'); }
  &--tone-6::before { background-image: url('../images/implications/implication-6.jpg'); }
  &--tone-7::before { background-image: url('../images/implications/implication-7.jpg'); }

  &--fixed {
    .implication__title {
      position: fixed;
      left: $outer-padding;
      top: 20vh;
    }

    .implication-toc {
      position: fixed;

      right: $outer-padding;
      top: 20vh;
    }
  }

  &--fixed-bottom {
    .implication-toc {
      position: absolute;
      bottom: 20vh;
      right: 0;
      top: auto
    }

    .implication__title {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__wrap {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    padding: rem-calc(120) 0;
    position: relative;
  }

  &__title {
    margin-bottom: rem-calc(60);
    margin-top: 0;
    position: absolute;
    text-shadow: 0 0 3px $dark-blue, 0 0 60px $dark-blue, 0 0 120px $dark-blue;
    transition: opacity $implication-transition-duration $implication-transition-timing;
    z-index: $z-implication-title;
  }

  &__content {
    margin-right: rem-calc(420);

    &-item {
      opacity: 0.2;
      transition: opacity $implication-transition-duration $implication-transition-timing;

      &--active { opacity: 0.9; }
    }

    &-title {
      font-size: rem-calc(28);
      margin-bottom: 2em;
    }

    &-section-title {
      display: none;
      font-size: 18px;
      margin-bottom: 0;
      text-transform: uppercase;

      + .implication__content-title {
        margin-top: 0;
      }
    }
  }

  &__image-preload {
    position: absolute;
    left: -9999em;
    top: -9999em;
  }
}

.implication-toc {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-top: rem-calc(176);
  text-align: right;

  position: absolute;
  right: 0;
  top: 0;

  &__title {
    position: absolute;
    bottom: 100%;
  }

  &__bar {
    background: #fff;
    content: "";
    height: 100%;
    width: 1px;

    position: absolute;
    right: 0;
    top: 0;
  }

  &__indicator {
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    content: "";
    height: 9px;
    margin-right: -4px;
    margin-top: -4px;
    transition: top $implication-transition-duration $implication-transition-timing;
    width: 9px;

    position: absolute;
    right: 0;
    // ((font-size / 2) * (1 + (vertical-padding)) * line-height)
    top: rem-calc((math.div(24, 2)) * 1.3 * 1.5);
  }

  &__item {
    color: #fff;
    font-weight: $weight-light;
    opacity: 0.5;
    padding: 0.15em 1.15em;
    transition: opacity $implication-transition-duration $implication-transition-timing;
    white-space: nowrap;

    &--active { opacity: 1; }
  }
}

.implication-list {
  @extend .plain-list;

  margin-bottom: 9em;

  &__item {
    line-height: 1;
    margin: 0.5em 0;
  }
}

@media screen and (max-height: 800px) {
  .implication {
    font-size: rem-calc(20);
    padding-top: 15vh;

    &--fixed {
      .implication__title { top: 15vh; }
      .implication-toc { top: 15vh; }
    }

    &--fixed-bottom .implication-toc {
      top: auto;
      bottom: 15vh;
    }
  }
}

@media screen and (max-width: 767px) {
  .implication {
    &--fixed {
      .implication__title { left: $outer-padding-mobile; }
      .implication-toc { right: $outer-padding-mobile; }
    }
    &--fixed-bottom {
      .implication-toc { right: 0; }
    }
  }
}

@media screen and (max-width: 625px) {
  .implication-toc { display: none; }
  .implication__content {
    margin-right: 0;

    &-section-title { display: block; }
    &-item { opacity: 0.9; }
  }

  .implication--fixed {
    .implication__title {
      position: relative;
      left: auto;
      top: auto;
    }
    .implication__wrap {
      padding-top: 0;
    }
  }
}
