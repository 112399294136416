.hero {
  align-items: center;
  background-color: $default-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: rem-calc(124);
  padding: rem-calc(130) 0;
  position: relative;

  &::before {
    background-image: url('../images/hero.jpg');
    background-position: 50% 50%;
    background-size: cover;

    content: "";
    height: 100%;
    opacity: 0.5;
    width: 100%;

    position: absolute;
    left: 0;
    top: 0;
  }

  &__content {
    color: #fff;
    max-width: rem-calc(1000);
    position: relative;
    text-align: center;
  }

  &__title {
    line-height: 1.1;
    font-size: rem-calc(70);
    font-weight: $weight-light;
  }

  &--processing::before {
    background-image: url('../images/hero-2.jpg');
  }

  &--left {
    align-items: flex-start;
    .hero__content { text-align: left; }
  }

  &--pulled {
    margin-top: rem-calc(-$header-spacing);
  }
}

@media screen and (max-width: 968px) {
  .hero__title {
    font-size: 50px;
  }
}

@media screen and (max-width: 767px) {
  .hero {
    padding-bottom: 12%;
    padding-top: 12%;
  }

  .hero__title {
    font-size: 32px;
    text-align: center;
  }
}

@media screen and (max-width: 625px) {
  .hero {
    margin-bottom: 35px;
  }
}
