$line-box-gutter: rem-calc(17);

.line-box-section {
  margin: 0 -#{$line-box-gutter} 100px;
}

.line-box-wrap {
  display: flex;
}

.line-box {
  border-top: 1px solid $light-blue;
  flex-grow: 1;
  font-size: rem-calc(22);
  flex-basis: 33%;
  flex-grow: 1;
  line-height: 1.3;
  margin: 0 $line-box-gutter;
  padding-top: 34px;

  &--large {
    flex-basis: 66%;
  }

  &--borderless {
    border-top-color: transparent;
  }

  &--tight-top > :first-child {
    margin-top: 0.25em;
  }

  &__actions {
    flex-basis: 100%;
    padding: 0 $line-box-gutter;
  }

  &__title {
    margin-top: 0;
  }
}

@media screen and (max-width: 968px) {
  .line-box-section {
    margin-bottom: 70px;
    margin-left: 0;
    margin-right: 0;
  }

  .line-box__actions {
    padding-left: 0;
    padding-right: 0;
    padding-top: 30px;
  }

  .line-box-wrap {
    flex-wrap: wrap;
  }

  .line-box {
    flex-basis: 100%;
    margin-left: 0;
    margin-right: 0;

    + .line-box  {
      border-top-width: 0;
      padding-top: 0;
    }
  }
}

@media screen and (max-width: 625px) {
  .line-box {
    border-top: none;
  }
}
