.services {
  background: linear-gradient(to right, #fff 50%, #f7f7f7 50%);
  padding: 8vh 0 0;
}

.services-wrap {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.services-categories {
  min-height: calc(100vh - 8vh);
  flex-basis: 50%;
  position: absolute;
  right: 50%;

  &__items {
    display: flex;
    flex-direction: column;
    font-size: rem-calc(24);
  }

  &__item {
    font-weight: $weight-light;
    line-height: 1;
    margin-bottom: 0;
    padding: 0.75em 0;

    &--active {
      font-weight: $weight-medium;
    }
  }

  .services--fixed & {
    position: fixed;
    bottom: auto;
    top: 8vh;
  }

  .services--fixed-bottom & {
    position: absolute;
    bottom: 0;
    top: auto;
  }
}

.services-description {
  flex-basis: 50%;

  &__rel-wrap {
    position: relative;
  }

  &__title {
    font-weight: $weight-regular;
    line-height: 1;
    margin: 0;
  }

  &__spacer {
    visibility: hidden;
  }

  &__list {
    margin: 0.8em 0 0;
    font-size: 0.95em;

    > li {
      line-height: 1;
      margin-bottom: 0.5em;
    }
  }

  &__items {
    font-size: rem-calc(22);
    padding-bottom: rem-calc(80);
    padding-top: rem-calc(80);

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-child(even) {
      background: #fff;
    }
  }

  &__item {
    padding: 0.45em 0;
  }

  &__content {
    opacity: 0;

    position: absolute;
    left: 0;
    right: 0;

    &--active {
      opacity: 1;
    }
  }
}

.services-mobile-description__items {
  background: #f0f0f0;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  padding-right: 42px;
  pointer-events: none;
  transition: max-height 225ms ease-in-out;

  &--active {
    pointer-events: all;
  }
}

@media screen and (max-width: 625px) {
  .services {
    background: #fff;

    &-wrap { flex-direction: column; }
    &-description { display: none; }

    &-description__item {
      &:first-child { margin-top: 14px; }
      &:last-child { margin-bottom: 14px; }
    }

    &-categories {
      flex-basis: 100%;
      position: static;
      right: auto;
    }

    &-categories__items {
      margin-left: -$outer-padding-mobile;
      margin-right: -$outer-padding-mobile;
    }

    &-categories__item {
      align-items: center;
      border-bottom: 1px solid $light-blue;
      display: flex;
      justify-content: space-between;
      padding: 1.75em $outer-padding-mobile;

      &::after {
        border: 5px solid transparent;
        border-top-color: rgba($default-color, 0.5);
        content: "";
        margin-bottom: -5px;
        margin-left: 10px;
      }

      &--active::after {
        border-top-color: transparent;
        border-left-color: rgba($default-color, 0.5);
        margin-bottom: 0;
      }
    }

    &--fixed .services-categories {
      position: static;
      top: auto;
    }

    &--fixed-bottom .services-categories {
      position: static;
      top: auto;
      bottom: auto;
    }
  }

  .services-mobile-description__items {
    font-size: 18px;
    padding-left: $outer-padding-mobile;
    position: relative;

    &--active { max-height: 1500px; }

    &::after {
      background-image: url('../images/icons/x.svg');
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      display: inline-block;
      flex-shrink: 0;
      height: 11px;
      margin-left: 10px;
      width: 11px;

      position: absolute;
      top: 32px;
      right: 20px;
    }
  }
}


@media screen and (max-height: 850px) and (min-width: 675px) {
  .services-categories__item {
    font-size: rem-calc(22);
  }

  .services-description__title {
    font-size: rem-calc(19);
  }
}

@media screen and (max-height: 650px) and (min-width: 675px) {
  .services-categories__item {
    font-size: rem-calc(20);
    padding-bottom: 0.5em;
    padding-top: 0.5em;
  }

  .services-description__title {
    font-size: rem-calc(18);
  }
}
