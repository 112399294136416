$member-animation-duration: 85ms;
$member-animation-timing-function: ease-in;

.team {
  &__title {
    text-shadow: 0 0 3px #fff, 0 0 60px #fff, 0 0 120px #fff;
    top: 0;
  }

  &__title-clone {
    display: none;
    pointer-events: none;
    visibility: hidden;
  }

  &--fixed {
    .team__title {
      padding-top: 34px;
      pointer-events: none;
      position: fixed;

      &-clone { display: block; }
    }
  }
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  margin: rem-calc(-18) rem-calc(-12) 0;

  &--bio-open .member {
    opacity: 0.5;

    &--with-bio {
      &:hover,
      &.member--active { opacity: 1; }
    }
  }
}

.member {
  align-items: stretch;
  display: flex;
  flex-basis: 25%;
  font-weight: $weight-medium;
  margin: rem-calc(18) 0;
  padding: 0 rem-calc(12);
  transition: opacity $member-animation-duration $member-animation-timing-function;

  &__details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__summary {
    display: flex;
    flex-direction: column;
    font-size: rem-calc(14);
    line-height: 1;
  }

  &__infos {
    display: flex;
    justify-content: space-between;
  }

  &__picture {
    background: #f9f9f9;
    box-shadow: inset 0 0 75px rgba(0, 0, 0, 0.18);
    margin-bottom: rem-calc(18);
    width: 100%;
  }

  &__name-wrap {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-size: 1.3em;
  }

  &__scholarship {
    color: #92a5b5;
    font-size: 1em;
    margin-bottom: 0.6em;
  }

  &__role {
    font-size: 1em;
  }

  &__bio {
    display: none;
  }

  &__bio-indicator {
    align-items: center;
    border: 1px solid rgba($default-color, 0.2);
    border-radius: 50%;
    display: none;
    flex-shrink: 0;
    height: 1.65em;
    justify-content: center;
    margin: -2px 0 -2px 5px;
    transition: border-color $member-animation-duration $member-animation-timing-function;
    width: 1.65em;

    &::after {
      background-image: url('../images/icons/arrow-down.svg');
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      content: "";
      display: inline-block;
      height: 0.285em;
      width: 0.642em;
    }
  }

  &--with-bio {
    cursor: pointer;

    .member__bio-indicator { display: flex; }
    &:hover .member__bio-indicator { border-color: rgba($default-color, 0.4); }
  }
}

.member-bios {
  flex-basis: 100%;

  &__item {
    display: none;
  }

  &__item-content {
    padding: rem-calc(60);
  }
}

.collapsable-bio {
  background: $light-grey;
  font-size: rem-calc(22);
  font-weight: $weight-light;
  position: relative;

  &::after {
    background-image: url('../images/icons/x.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 11px 11px;
    content: "";
    cursor: pointer;
    display: inline-block;
    height: 11px;
    padding: rem-calc(14);
    width: 11px;

    position: absolute;
    right: 0;
    top: 0;
  }
}

// IE 10 + IE 11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .member {
    max-width: 25%;
  }

  .member,
  .member__summary,
  .member__scholarship,
  .member__name-wrap,
  .member__details {
    display: block;
  }
}

@media screen and (max-width: 968px) {
  .team--fixed .team__title {
    padding-top: 0;
    position: relative;
    left: auto;
    top: auto;

    &-clone {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .team-members {
    margin: 0 -#{$outer-padding-mobile};
  }

  .member {
    border-top: 1px solid $light-blue;
    flex-basis: 100%;
    flex-direction: column;
    margin: 0;
    padding-left: 0;
    padding-right: 0;

    &__details {
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      padding-right: $outer-padding-mobile;
    }

    &__picture {
      margin-bottom: 0;
      max-width: 30%;
      margin-right: rem-calc(18);
    }

    &__summary {
      font-size: 20px;
      flex-grow: 1;
      padding-bottom: 0.5em;
      padding-top: 1em;
    }

    &__bio {
      display: block;
      max-height: 0;
      overflow: hidden;
      transition: max-height 225ms ease-in-out;

      &-content {
        background: $light-grey;
        padding: $outer-padding-mobile;
      }
    }

    &-bios {
      display: none;
    }

    &--active .member__bio {
      max-height: 800px;
    }
  }
}

@media screen and (max-width: 625px) {
  .member__summary {
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  .member__summary {
    font-size: 14px;
  }
}
