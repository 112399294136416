.offices-layout {
  display: flex;
  justify-content: space-between;

  &__content {
    flex-grow: 1;
  }

  &__map {
    flex-basis: 45%;
    flex-shrink: 0;
    margin-right: -$outer-padding;
  }

  &__highlight {
    background: $default-color;
    color: #fff;
    font-size: rem-calc(22);
    line-height: 1.3;
    margin-left: -$outer-padding;
    overflow: hidden;
    padding: rem-calc(10) 0;

    display: flex;
    flex-direction: row;
    align-items: center;

    .inner {
      padding: 0 rem-calc(10);
      font-size: rem-calc(15);
    }

    a { color: #fff; }
    p { max-width: rem-calc(500); }
  }
}

.offices {
  display: flex;
  flex-wrap: wrap;
  margin-right: $outer-padding;
}

.office {
  flex-basis: 50%;
  margin-bottom: rem-calc(50);
  padding-right: 20px;
  padding-top: rem-calc(45);
  position: relative;

  &__title {
    font-size: rem-calc(15);
    margin-bottom: 1.6em;
    margin-top: 0;
    text-transform: uppercase;
  }

  &__content {
    font-size: rem-calc(22);
  }

  &__contact {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    line-height: 1;
    margin-bottom: 1.2em;
    min-height: 2.6em;

    a {
      font-weight: $weight-light;
      margin-bottom: .3em;
    }
  }

  &__address {
    line-height: 1.4;
    a { font-weight: $weight-light; }
  }

  &--large {
    flex-basis: 100%;

    .office {
      &__title {
        font-size: 20px;
        margin-bottom: 1.4em;
      }

      &__content {
        font-size: rem-calc(40);
      }

      &__contact {
        margin-bottom: 1.35em;
      }

      &__address {
        line-height: 1;
      }
    }
  }

  &:first-child::before {
    border-top: 1px solid $light-blue;
    content: "";
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: 1400px) {
  .offices-layout__map {
    max-width: rem-calc(450);
  }
}

@media screen and (max-width: 950px) {
  .offices-layout {
    flex-direction: column;

    &__map {
      height: rem-calc(450);
      margin-left: -$outer-padding;
      margin-right: -$outer-padding;
      max-width: none;
    }

    &__highlight {
      margin-right: -$outer-padding;
    }
  }

  .offices {
    margin-right: 0;
  }

  .office--large .office__content {
    font-size: rem-calc(30);
  }
}

@media screen and (max-width: 767px) {
  .offices-layout {
    &__map,
    &__highlight {
      margin-left: -$outer-padding-mobile;
      margin-right: -$outer-padding-mobile;
    }
  }
}

@media screen and (max-width: 680px) {
  .office {
    margin-bottom: 10%;

    &__contact {
      margin-bottom: 5%;
    }

    &:first-child::before {
      width: 100%;
    }
  }
}

@media screen and (max-width: 500px) {
  .office {
    flex-basis: 100%;
    padding-right: 0;

    &__contact { min-height: 0; }
  }
}
