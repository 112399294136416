.mosaic {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: rem-calc(124);

  &__tile {
    display: flex;
    flex-basis: 33.33%;
    flex-direction: column;
    flex-grow: 1;
    font-size: rem-calc(18);
    line-height: 1.3;
    padding: rem-calc(85) rem-calc(70);
    justify-content: center;

    > :first-child { margin-top: 0; }

    &--blue {
      background: $default-color;
      color: #fff;
    }

    &--image {
      align-items: flex-start;
      background-image: url('../images/mosaic.jpg');
      background-position: 50% 50%;
      background-size: cover;
      justify-content: flex-end;
    }

    &--large {
      flex-basis: 66.66%;
    }

    &--centered {
      text-align: center;
    }

    &--shaded {
      background: $light-grey;
    }
  }

  &__data-highlight {
    display: block;
    font-size: rem-calc(150);
    letter-spacing: rem-calc(-10);
    line-height: 0.9;
  }

  &__logo {
    align-items: center;
    color: #fff;
    display: flex;
    justify-content: center;
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .mosaic {
    margin-bottom: 35px;
  }

  .mosaic__tile {
    min-width: 250px;
    padding-left: $outer-padding-mobile;
    padding-right: $outer-padding-mobile;
  }

  .mosaic__tile--image {
    display: none;
  }
}
