.footer {
  background: $light-grey;
  padding: 25px 0;

  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__contact {
    display: flex;

    &-item {
      font-size: rem-calc(15);
      margin: 0 2.4em;
    }
  }

  &__logo {
    flex-grow: 1;
  }
}

@media screen and (max-width: 1280px) {
  .footer__contact-item {
    font-size: 15px;
    margin: 0 3%;
  }
}

@media screen and (max-width: 850px) {
  .footer {
    &__logo { flex-basis: 50%; }
    &__inner { flex-wrap: wrap; }

    &__contact {
      &-item {
        margin: 0 0 1em;
        padding: 0;

        &--important {
          flex-basis: 50%;
          text-align: right;
        }
      }
    }
  }
}

@media screen and (max-width: 525px) {
  .footer__contact-item:last-child {
    flex-basis: 100%;
  }
}
