.badge {
  background: #5596d1;
  border-radius: 0.55em;
  color: #fff;
  display: inline-block;
  font-size: rem-calc(14);
  font-weight: $weight-medium;
  margin-right: 0.5em;
  padding: 0.425em 0.85em;
  vertical-align: middle;

  &--news {
    font-size: 0.35em;
  }
}
