.single-news {
  font-size: rem-calc(20);
  max-width: 780px;
  margin: 0 auto rem-calc(120);

  &__title {
    line-height: 1;
    margin-top: 0;
  }

  &__date {
    font-weight: bold;
    text-transform: uppercase;
    opacity: 0.7;
  }

  &__files {
    display: flex;
    flex-direction: column;
  }

  &__file {
    align-items: center;
    background: rgba($default-color, 0.08);
    display: flex;
    font-size: rem-calc(16);
    margin-bottom: 2px;
    padding: 6px 12px;

    &::before {
      background-image: url('../images/icons/download.svg');
      background-size: cover;
      content: "";
      display: inline-block;
      height: 24px;
      margin-right: 10px;
      width: 24px;
    }
  }
}
