.news {
  @extend .plain-list;

  margin-bottom: rem-calc(120);

  &__item {
    align-items: stretch;
    display: flex;

    &:hover {
      opacity: 1;
      background-color: #f7f9fb;
    }

    &--imageless {
      padding-right: rem-calc(450);
    }
  }

  &__content {
    flex-grow: 1;
    padding-bottom: rem-calc(50);
    padding-top: rem-calc(50);
  }

  &__title-wrap {
    display: flex;
    flex-direction: column-reverse;
  }

  &__title {
    font-size: rem-calc(40);
    margin-top: rem-calc(10);
    line-height: 1;
  }

  &__date {
    font-size: rem-calc(15);
    text-transform: uppercase;
  }

  &__excerpt {
    font-size: rem-calc(22);
    font-weight: $weight-light;
    margin: 0;
  }

  &__image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
    min-height: 250px;
    width: rem-calc(450);
  }

  @media screen and (max-width: 860px) {
    &__item {
      &--imageless {
        padding-right: rem-calc(300);
      }
    }
    &__image {
      width: rem-calc(300);
    }
  }

  @media screen and (max-width: 680px) {
    &__item {
      align-items: stretch;
      flex-direction: column-reverse;

      &--imageless {
        padding-right: 0;
      }
    }

    &__content {
      padding-bottom: rem-calc(80);
      padding-top: rem-calc(80);
    }

    &__image {
      width: 100%;
    }
  }
}
