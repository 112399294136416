*, *::before, *::after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

img {
  max-width: 100%;
}

.inner {
  padding: 0 $outer-padding;
}

.content {
  flex-grow: 1;
  z-index: $z-content;
}

@media screen and (max-width: 767px) {
  .inner {
    padding-left: $outer-padding-mobile;
    padding-right: $outer-padding-mobile;
  }
}
