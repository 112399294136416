$tagline-duration: 2s;
$tagline-timing-function: cubic-bezier(0, 0, 0.2, 1);

.tagline-looper {
  background-color: #012544;
  color: #fff;
  height: rem-calc(600);
  margin-bottom: rem-calc(124);
  margin-top: rem-calc(-$header-spacing);
  position: relative;

  &::before {
    background-image: url('../images/implications/implication-3.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    content: "";
    height: 100%;
    opacity: 0.2;
    pointer-events: none;
    transition: background-image $tagline-duration $tagline-timing-function;
    width: 100%;

    position: absolute;
    left: 0;
    top: 0;
  }

  &--alternate::before {
    background-image: url('../images/implications/implication-5.jpg');
  }

  &__inner {
    height: 100%;
  }

  &__relative {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
  }

  &__title {
    line-height: 1.1;
    font-size: rem-calc(70);
    font-weight: $weight-light;
    left: 0;
    margin: 0;
    max-width: 50%;
    position: absolute;
    width: 100%;

    &--blurred {
      left: 50%;
      opacity: 0.2;
      text-shadow: 0 0 5px #fff, 0 0 15px #fff, 0 0 25px #fff, 0 0 35px #fff;
      transform: scale(0.65);
    }

    &--anim-blur,
    &--anim-unblur {
      animation-duration: $tagline-duration;
      animation-fill-mode: forwards;
      animation-timing-function: $tagline-timing-function;
    }
    &--anim-unblur {
      animation-name: unblur;
    }
    &--anim-blur {
      animation-name: blur;
    }
  }
}

@keyframes unblur {
  0%   {
    opacity: 0.2;
    left: 50%;
    transform: scale(0.65);
    text-shadow: 0 0 5px #fff, 0 0 15px #fff, 0 0 25px #fff, 0 0 35px #fff;
  }

  66%  {
    transform: scale(0.65 + (0.35 * 0.25));
    left: (0.5 - (0.5 * 0.75));
  }

  100% {
    left: 0;
    opacity: 1;
    transform: scale(1);
    text-shadow: none;
  }
}

@keyframes blur {
  0% {
    left: 0;
    opacity: 1;
    transform: scale(1);
    text-shadow: none;
  }

  66%  {
    transform: scale(0.65 + (0.35 * 0.25));
    left: (0.5 - (0.5 * 0.75));
  }

  100%   {
    opacity: 0.2;
    left: 50%;
    transform: scale(0.65);
    text-shadow: 0 0 5px #fff, 0 0 15px #fff, 0 0 25px #fff, 0 0 35px #fff;
  }
}

@media screen and (max-width: 1000px) {
  .tagline-looper {
    max-height: rem-calc(500);

    &__title {
      font-size: rem-calc(60);
    }
  }
}

@media screen and (max-width: 800px) {
  .tagline-looper {
    max-height: rem-calc(425);

    &__title {
      font-size: rem-calc(55);
    }
  }
}

@media screen and (max-width: 767px) {
  .tagline-looper {
    margin-bottom: rem-calc(35);
    max-height: rem-calc(350);

    &__title {
      font-size: rem-calc(50);
    }
  }
}

@media screen and (max-width: 500px) {
  .tagline-looper {
    justify-content: center;

    &__title {
      left: auto;
      text-align: center;
      transform-origin: center;

      &--blurred {
        left: auto;
        opacity: 0;
      }
    }
  }
}

