@use "sass:math";

$header__transition-duration: 90ms;
$header__transition-timing-function: ease-out;
$header__condensed-padding: 9px;
$header__item-padding: 40px;

.header {
  $root: &;
  margin-bottom: rem-calc($header-spacing);
  position: relative;
  z-index: $z-header;

  &__inner {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: $z-header-inner;
  }

  &__logo {
    align-items: center;
    display: flex;
    margin: 23px 27px 23px 0;

    svg {
      display: inline-block;
      max-width: 100%;
      vertical-align: middle;
      width: 229px;
    }
  }

  &-nav {
    align-items: baseline;
    background: $default-color;
    display: flex;
    margin-bottom: -14px;
    margin-right: -$outer-padding;
    padding: 0 50px 0 20px;
    z-index: 1000;

    right: 0;
    top: 0;

    &--initialized {
      position: fixed;
      margin-right: 0;

      transition-duration: $header__transition-duration;
      transition-property: padding;
      transition-timing-function: $header__transition-timing-function;

      .header-nav__item {
        transition-duration: $header__transition-duration;
        transition-property: font-size, padding;
        transition-timing-function: $header__transition-timing-function;
      }
    }

    &__item {
      color: #fff;
      font-size: rem-calc(20);
      padding: $header__item-padding 20px;

      &-locale {
        font-size: rem-calc(16);
        font-weight: $weight-bold;
        text-transform: uppercase;
      }

      &--home {
        opacity: 0.5;

        svg {
          height: rem-calc(12);
          width: rem-calc(12);
        }
      }
    }

    &__mobile-toggler {
      align-items: center;
      color: #fff;
      cursor: pointer;
      display: none;
      font-size: rem-calc(16);
      font-weight: $weight-bold;
      padding: $header__item-padding $outer-padding;
      text-transform: uppercase;

      path {
        fill: #fff;
      }

      &-icon {
        display: inline-block;
        height: 19px;
        margin-left: 8px;
        text-align: center;
        width: 24px;

        svg { height: 100%; }
      }

      &-close { display: none; }
      &-open { display: flex; }
    }
  }

  &--mood {
    background-image: url('../images/mood/who-we-are.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    margin-bottom: rem-calc(math.div($header-spacing, 2));
    padding-bottom: 18vw;

    &::before {
      background: linear-gradient(165deg, rgba(0, 0, 0, 0.20) 150px, rgba(0, 0, 0, 0) 300px);
      content: "";
      height: 100%;
      width: 100%;
      z-index: $z-header-filter;

      position: absolute;
      top: 0;
      left: 0;
    }

    #{$root}__logo {
      path {
        fill: #fff;
      }
      #{$root}__logo-part-top {
        fill: #999;
      }
      #{$root}__logo-part-bottom {
        fill: #b2b2b2;
      }
    }

    &-about { background-image: url('../images/mood/who-we-are.jpg'); }
    &-expertise { background-image: url('../images/mood/what-we-do.jpg'); }
  }

  :not(.header--open) .header-nav--condensed .header-nav__mobile-toggler {
    padding-bottom: $header__condensed-padding;
    padding-top: $header__condensed-padding;
  }

  label {
    @extend a;
    @extend .header-nav__item;
    @extend .header-nav__item-locale;
    cursor: pointer;
  }
}

@media screen and (min-width: 968px) {
  .header-nav--condensed .header-nav__item {
    font-size: rem-calc(16);
    padding-bottom: $header__condensed-padding;
    padding-top: $header__condensed-padding;
  }
}

@media screen and (max-width: 968px) {
  .header-nav {
    align-items: stretch;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;

    &__mobile-toggler {
      display: flex;
    }

    &__item {
      display: none;
      font-weight: $weight-light;
      font-size: rem-calc(38);
      padding: rem-calc(17) $outer-padding;

      &-locale {
        background: #00315c;
        flex-grow: 1;
        font-size: rem-calc(20);
        font-weight: $weight-medium;
        margin-top: 50px;
      }
    }
  }

  .header--open {
    .header-nav {
      flex-direction: column;
      min-height: 100vh;

      position: fixed;
      left: 0;
      right: 0;
      top: 0;

      .header-nav__item {
        display: block;
        width: 100%;

        &--home {
          display: none;
        }
      }

      .header-nav__mobile-toggler {
        justify-content: flex-end;
        margin-bottom: 25px;
        width: 100%;

        &-close { display: flex; }
        &-open { display: none; }
      }
    }

    .header__logo {
      position: fixed;
      z-index: 2000;

      &-text {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .header-nav {
    &__item,
    &__mobile-toggler {
      padding-left: $outer-padding-mobile;
      padding-right: $outer-padding-mobile;
    }
  }
}
