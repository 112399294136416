.home-news {
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &-title {
      align-items: flex-start;
      display: flex;
      min-height: 2.3em;
    }

    &-excerpt > :first-child { margin-top: 0; }
  }
}

@media screen and (max-width: 968px) {
  .home-news__item {
    margin-bottom: 25px;
    &-title { min-height: 0; }
  }
}
